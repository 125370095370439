import lightGallery from 'lightgallery';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import { Modal } from 'bootstrap/js/dist/modal';
import { Dropdown } from 'bootstrap/js/dist/dropdown';
import { OffCanvas } from 'bootstrap/js/dist/offcanvas';

import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  initLightGalleries();
  initSliderFeedbacks();

  if (document.querySelector('[data-navclose]')) {
    document.querySelectorAll('[data-navclose]').forEach((el) => {
      el.addEventListener('click', closeNav);
    });
  }

  if (document.querySelector('[data-navtoggler]')) {
    document.querySelectorAll('[data-navtoggler]').forEach((el) => {
      el.addEventListener('click', toggleNav);
    });
  }

  if (document.querySelector('[data-nav]')) {
    document
      .querySelector('[data-nav]')
      .addEventListener('click', navClickHandler);
  }

  if (
    document.querySelector('[data-seconds-gift]') &&
    localStorage.getItem('isGiftTimer') === null
  ) {
    giftTimer();
  }

  if (document.querySelector('.card-gift')) {
    document.querySelector('.card-gift').addEventListener('click', () => {
      window.seconds = -1;
      triggerModal();
    });
  }

  const sliderObjects = new Swiper('.slider-objects', {
    loop: true,
    watchSlidesProgress: true,

    navigation: {
      nextEl: '.swiper-button-next',
      //prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1200: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1600: {
        slidesPerView: 2,
        spaceBetween: 60,
      },
    },
  });

  const badgesVisibilityToggler = document.querySelector(
    '[data-toggle-check-show]',
  );

  if (badgesVisibilityToggler) {
    showHideOverflowBadges();

    badgesVisibilityToggler.addEventListener('click', showHideOverflowBadges);

    window.addEventListener('resize', showHideOverflowBadges);
  }
});

function showHideOverflowBadges() {
  const listBadges = document.querySelector('.list-badges');
  const badges = listBadges.querySelectorAll('.check-badge');

  badges.forEach((badge) => {
    badge.classList.remove('d-none');

    const rect = badge.getBoundingClientRect();
    const listRect = listBadges.getBoundingClientRect();

    // Проверяем, полностью ли элемент виден в контейнере
    if (rect.left >= listRect.left && rect.right <= listRect.right) {
      badge.classList.remove('d-none');
    } else {
      badge.classList.add('d-none');
    }
  });
}

window.resetGiftTimer = () => {
  localStorage.removeItem('isGiftTimer');
};
function giftTimer() {
  const timerEl = document.querySelector('[data-seconds-gift]');
  window.seconds = Number.parseInt(timerEl.textContent) | 30;

  document.querySelector('.card-gift')?.classList.remove('d-none');

  if (window.seconds > 0) {
    decrementAndStartFunction(timerEl);
  }
}

function decrementAndStartFunction(timerEl) {
  if (window.seconds === 1) {
    triggerModal();
    return;
  }
  if (window.seconds === -1) {
    return;
  }

  window.seconds--;
  timerEl.textContent = window.seconds;

  setTimeout(() => {
    decrementAndStartFunction(timerEl);
  }, 1000);
}

function triggerModal() {
  const cardGiftEl = document.querySelector('.card-gift');
  cardGiftEl.click();
  cardGiftEl.classList.add('d-none');

  localStorage.setItem('isGiftTimer', 'Таймер уже был вызван');
}

// Функция инициализации галерей изображений
function initLightGalleries() {
  const galleryElements = document.querySelectorAll('[data-gallery]');
  galleryElements.forEach((el) => {
    lightGallery(el, {
      licenseKey: '0000 0000 0000 0000',
      download: false,
      fullScreen: false,
      counter: false,
      plugins: [lgFullscreen, lgVideo],
      selector: '[data-src]',
    });
  });
}

function initSliderFeedbacks() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    //loop: true,
    spaceBetween: 24,
    initialSlide: 1,
    watchSlidesProgress: true,
    centeredSlides: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 3,
      },
    },
  });
}

function closeNav() {
  document.querySelector('[data-nav]').classList.remove('active');
}

function toggleNav() {
  document.querySelector('[data-nav]').classList.toggle('active');
}

function navClickHandler(e) {
  if (e.target.tagName !== 'A') {
    closeNav();
  }
}

// Экспорт модального окна для доступа извне
window.modal = Modal;
window.showHideOverflowBadges = showHideOverflowBadges;

function getRandomNumber() {
  return (Math.random() * 0.3 + 0.1).toFixed(1);
}

/* Параллакс */
document.addEventListener('DOMContentLoaded', function () {
  const columns = document.querySelectorAll('.block-paralax__column');

  function updateParallax() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (windowWidth <= 992) {
      columns.forEach((column) => {
        column.style.transform = 'none'; // Reset transform for smaller screens
      });
      return;
    }

    columns.forEach((column) => {
      const columnRect = column.getBoundingClientRect();
      if (columnRect.top < windowHeight && columnRect.bottom > 0) {
        let speed = column.dataset.paraspeed;
        const scrollPosition = window.scrollY || window.pageYOffset;
        const offset = (columnRect.top - windowHeight) * speed; // Adjust the multiplier to control the speed
        column.style.transform = `translateY(${offset}px)`;
      }
    });
  }

  window.addEventListener('scroll', updateParallax);
  window.addEventListener('resize', updateParallax);
  updateParallax(); // Initial update
});
/* Конец Паралакса */
